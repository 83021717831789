@import '../../styles/base/base';

.main-buttons {
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 7;
  border-radius: 31.662px;
  background: rgba(255, 255, 255, 0.33);
  backdrop-filter: blur(2.0730769634246826px);
  gap: 26px;
  padding: 7px 12px;
  color: white;
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;

  &>.main-button {
    padding: 7px 8px;
    border-radius: 25px;
    cursor: pointer;
    white-space: nowrap;

    &.active {
      background: $primary-color;
    }
  }

  .separator {
    width: 1px;
    height: 100%;
    background-color: white;
    padding: 10px 0;
  }

  @media only screen and (min-width: 768px) {
    bottom: 30px;
    padding: 8px 16px;
    gap: 36px;
    border-radius: 42px;
    background: rgba(255, 255, 255, 0.33);
    backdrop-filter: blur(2.75px);

    &>.main-button {
      padding: 10px 16px;
      border-radius: 34px;

      &:hover {
        background: $hover-color;
      }
    }
  }
}