@import "../../styles/base/base";

.primaryColor {
  background-color: $primary-color;
  padding: 7px 0;
}

.logo {
  height: 24px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(calc(-50%), -50%);
  filter: invert(38%) sepia(75%) saturate(4203%) hue-rotate(330deg)
    brightness(98%) contrast(96%);
}

.AnchorNode {
  transform: translate(-19px, -188px);
}

.marker-container {
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;
  top: 0px;
  left: 0px;
}

.marker {
  position: relative;
  width: 34px; /* Adjust size */
  height: 34px; /* Adjust size */
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.75);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: box-shadow 0.5s ease-out, background-color 0.5s ease-out;
  margin-top: 10px;
  overflow: hidden;
}

.marker._3d {
  background: $primary-color;
}

.marker._3d .logo{
  filter: none;

}

.AnchorNode:has(.marker._3d) .card.hiden {
  border: 1px solid antiquewhite;
}


.line {
  width: 2px; /* Adjust width */
  height: 100px; /* Adjust height */
  background-color: $primary-color; /* Line color */
  margin-top: -5px; /* Adjust margin to position the line */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.5s ease-out;
}

.AnchorNode:has(.card.visible) .marker {
  box-shadow: 0px 1px 11px -1px rgba(0, 0, 0, 0.63);
  -webkit-box-shadow: 0px 1px 11px -1px rgba(0, 0, 0, 0.63);
  -moz-box-shadow: 0px 1px 11px -1px rgba(0, 0, 0, 0.63);
  background: $primary-color;
  transition: box-shadow 0.5s ease-out, background-color 0.5s ease-out;
}
.AnchorNode:has(.card.visible) .marker + .line {
  box-shadow: 0 0 2px 2px #fff, 0 0 3px 3px rgb(75, 83, 83);
  transition: box-shadow 0.5s ease-out;
}
.AnchorNode:has(.card.visible) .marker .logo {
  filter: none;
}

.m_marker {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #fff;
}

.card {
  position: absolute;
  transform: translate(50px, -80%);
  display: flex;
  width: 303px;
  height: 239px;
  padding-bottom: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  flex-shrink: 0;
  border-radius: 14px;
  background: rgba(255, 255, 255, 0.33);
  backdrop-filter: blur(2.75px);
  min-height: 28px;
  overflow: hidden;
}

.card img {
  width: 303px;
  height: 175.239px;
  flex-shrink: 0;
  border-radius: 14px 14px 8.632px 8.632px;
  background: lightgray 50% / cover no-repeat;
}

.card .name {
  text-align: left;
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 133.333% */
}
.card .location {
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  text-overflow: ellipsis;
  height: 25px;
  width: 250px;
  text-align: left;
  overflow: hidden;
}
.card .location::after {
  height: 20px;
}
.card path {
  color: white;
}


.AnchorNode {
  .coming-soon {
    display: none;
  }

  .division-name {
    display: block;
  }

  &.has-coming-soon {
    &:hover {
      .coming-soon {
        display: block;
      }
    
      .division-name {
        display: none;
      }
    }
  }
}

@keyframes heightChange2 {
  100% {
    height: fit-content;
  }
}

@keyframes widthChange2 {
  0% {
    width: 303px;
  }
  99% {
    width: 180px;
  }
  100% {
    width: fit-content;
  }
}

.card.hiden {
  // width: fit-content;
  width: 303px;
  padding: 0px 10px;
  transform: translate(39px, 8px);
  height: 36px;
  border-radius: 6px;
  background: rgba(255, 255, 255, 0.33);
  backdrop-filter: blur(2.75px);
  animation: heightChange2 0.3s ease-out forwards,
    widthChange2 0.3s ease-out forwards;
  transition: transform 0.5s, opacity 0.3s ease-out 0.3s;
}

.card.hiden img {
  visibility: hidden;
  opacity: 0;
  height: 0;
  width: 0;
}

.card.visible img {
  width: 303px;
  height: 175.239px;
  visibility: visible;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.card.visible .big_name {
  visibility: visible;
  padding-left: 16px;
  opacity: 1;
  transition: opacity 0.2s ease 0.3s;
}

.card.hiden .big_name {
  visibility: hidden;
  height: 0;
  padding: 0;
  margin: 0;
  opacity: 0;
  width: 0;
}
@keyframes heightChange {
  0% {
    height: 36px;
  }
  100% {
    height: 212px;
  }
}

.card.visible {
  width: 303px;
  height: 36px;
  // height: 239px;
  opacity: 1;
  box-shadow: 0px -1px 3.8px 0px rgba(0, 0, 0, 0.18);
  background: rgba(255, 255, 255, 0.5);
  transform: translate(44px, -82%);
  animation: heightChange 0.3s ease-out 0.1s forwards;
  transition: width 0.1s ease-out, transform 0.3s ease-out 0.1s,
    opacity 0.3s ease-out 0.1s;
}

.name.hiden {
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}

.name.hiden.des {
  color: #fff;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
}
.AnchorNode {
  position: absolute;
  cursor: pointer;
  width: 350px;
  height: 61px;
}

.AnchorNode:has(.marker._2d) {
  // cursor:default;
}

.AnchorNode:has(.card.hiden) {
  z-index: 1;
  width: 178px;
}
.AnchorNode:has(.card.visible) {
  z-index: 100;
}

.AnchorNode *,
.AnchorNodeFly * {
  text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.71);
}

.card.left {
  transform: translate(-130px, 8px);
}

@keyframes translateSequence {
  0% {
    transform: translateX(-130px) translateY(0);
  }
  25% {
    transform: translateX(-321px);
  }
  100% {
    transform: translateX(-321px) translateY(-188px);
  }
}

@keyframes translateSequenceRev {
  0% {
    transform: translateX(-321px) translateY(-188px);
  }
  25% {
    transform: translateX(-321px) translateY(0);
  }
  100% {
    transform: translateX(-130px) translateY(0);
  }
}

.card.left.visible {
  pointer-events: none;
  transition: width 0.1s ease-out, height 0.3s ease-out 0.1s,
    opacity 0.3s ease-out 0.1s;
  animation: translateSequence 0.4s ease-out forwards;
}

.card.left.hiden {
  pointer-events: none;
  width: 126px;
  padding: 0px 10px;
  transform: translateX(-321px) translateY(-188px);
  height: 36px;
  border-radius: 6px;
  background: rgba(255, 255, 255, 0.33);
  backdrop-filter: blur(2.75px);
  transition: height 0.05s ease-out, width 0.3s ease-out 0.1s,
    opacity 0.3s ease-out 0.1s;
  animation: translateSequenceRev 0.4s ease-out forwards;
}

.AnchorNode.hiden:has(.card.left) .extraDiv {
  width: 141px;
  height: 48px;
  position: absolute;
  left: -138px;
  top: -3px;
}

.AnchorNode.visible:has(.card.left) .extraDiv {
  width: 333px;
  height: 268px;
  position: absolute;
  left: -331px;
  top: -199px;
}

.extraDiv{
  position: absolute;
}
.AnchorNode.visible .extraDiv {
  width: 367px;
  height: 272px;
  top: -215px;
}

.AnchorNode.hiden .extraDiv {
  width: 38px;
  height: 44px;
  position: absolute;
  top: 0;
}


.AnchorNode:has(.card.left) {
  width: 0;
  height: 0;
}

.AnchorNodeFly .card.hiden {
  transform: translate(70px, -45px);
}

.AnchorNodeFly .card.visible {
  transform: translate(72px, -241px);
}

.AnchorNodeFly {
  position: absolute;
}
.AnchorNodeFly .name.hiden {
  width: max-content;
}
img{
  user-select: none;
}