$primary-color: #e31b53;
$secondary-color: #ff0000;
$success-color: #52C41A !default;
$info-color: #6a6ed4 !default;
$warning-color: #FAAD14 !default;
$danger-color: #FF4D4F !default;

$border-color: #da1c5b;
$hover-color: #cd1348;

$padding-base: 15px !default;
$margin-base: 15px !default;

$border-radius-base: 12px !default;