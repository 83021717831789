@import "../../styles/base/base";

.user-manual img {
  background-color: $primary-color;
}

.user-manual {
  width: 669px;
  height: 423px;
  flex-shrink: 0;
  border-radius: 12px;
  background: #d91a5a;
  position: relative;
}

.icon_close {
  color: #a0447b;
}

.user-manual .container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0;
  background-color: #e30074;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

.user-manual .box {
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  color: white;
  border-left: #eee8ec 1px solid;
}

.user-manual h3 {
  color: #fff;
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}

.user-manual p {
  line-height: 1.5;
}

.column {
  float: left;
  width: 50%;
  padding: 12px 10px;
  height: 100%;
}

.row > .column > .row > .column {
  border: none;
}

.user-manual > .row > .column {
  padding-top: 47px;
  //     padding-left: 26px;
}
.column.full {
  width: 100%;
}
.column svg {
  float: left;
  width: 100%;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.row {
  height: 50%;
}

.column p {
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 114.286% */
}
.column.fit {
  padding: 0;
  height: auto;
  display: flex;
  justify-content: center;
}

.column.fit svg {
  width: fit-content;
  margin: 5px;
}

.column.cell p {
  padding-top: 58px;
  text-align: center;
}

.manual_mobile {
  display: inline-flex;
  width: 308px;
  height: 161px;
  padding: 37px 15px 12px 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 12px;
  background: #d91a5a;
}
