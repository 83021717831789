@import '../../styles/base/base';

$width:38px;
$height: 38px;

.action-buttons {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 7;
  color: white;

  .action-group {
    border-radius: 8px;
    border: 1px solid rgba(233, 234, 234, 0.00);
    background: rgba(255, 255, 255, 0.33);
    box-shadow: 0px -1px 3.8px 0px rgba(0, 0, 0, 0.18);
    margin-top: 10px;
  }

  .action-btn {
    width: $width;
    height: calc($height - 2px);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &.alone {
      height: $height;
    }

    &.active {
      color: $primary-color;
    }

    &:hover {
      color: $hover-color;
    }

    &.top-btn {
      border-radius: 8px;
      background-color: $primary-color;
      width: calc($width + 1.6px);
      height: calc($width + 1.6px);

      &:hover {
        background-color: $hover-color;
        color: white;
      }
    }
  }

  @media only screen and (min-width: 768px) {
    $width:34px;
    $height: 34px;
    top: 26px;

    .action-group {
      &:not(:first-child) {
        margin-top: 8px;
      }
    }

    .action-btn {
      width: $width;
      height: calc($height - 2px);

      &.alone {
        height: $height;
      }

      &.top-btn {
        width: calc($width + 1.6px);
        height: calc($width + 1.6px);
      }

      svg {
        font-size: 14px;
      }
    }
  }

  // On touch device
  @media (hover: none) {
    .action-btn:not(.active):hover {
      color: white !important;
    }
  }
}

.action-buttons.mobile{
  .action-group{
    margin-top: 8px;
  }
}