@font-face {
  font-family: 'SVN-Moneta';
  src: url('../../assets/fonts/SVN-Moneta-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SVN-Moneta';
  src: url('../../assets/fonts/SVN-Moneta-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../../assets/fonts/Montserrat-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../../assets/fonts/Montserrat-Italic.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../../assets/fonts/Montserrat-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../../assets/fonts/Montserrat-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../../assets/fonts/Montserrat-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}


@font-face {
  font-family: 'Montserrat';
  src: url('../../assets/fonts/Montserrat-LightItalic.woff2') format('woff2');
  font-weight: 300;
  font-style: italic;
}